import React, { useState, useEffect } from 'react';
import { Row, Typography, Col, Timeline } from 'antd';
import RightImageSection from '../../components/RightImageSection/RightImageSection';
// import LeftImageSection from '../../components/LeftImageSection/LeftImageSection';
import Comments from '../../components/Comments/Comments';

import './about.css';
// import bgSection from '../../assets/bg_section.jpg';
import labout from '../../assets/rsz_about_nobg.png';

const { Title } = Typography;

// const rIMGURL = bgSection;
// const rTitle = 'ABOUT THE BOOK';
// const rText = `Uncover moments where resilience, faith, and an abundance mindset collided to shape a narrative of triumph over adversity. Let this be an inspiration for girls to rise above limitations and embrace their full potential. Explore the depths of courage and perseverance as you navigate through the pages of empowerment, guided by Evelyne's remarkable life experiences.`;

const lIMGURL = labout;
const lTitle = 'ABOUT THE AUTHOR';
const lText = `Evelyne Mshai Mwakina is a highly accomplished individual with 34 years of stellar public service. She ascended from the position of a translator to becoming the Corporate Affairs and Communications Manager at the Kenya Broadcasting Corporation. Evelyne holds a BA in Literature, Kiswahili, and Philosophy in Religious Studies from Kenyatta University, an Executive MBA from the Eastern and Southern African Management Institute and the Maastricht School of Management, and a Mass Communication Postgraduate Diploma from the University of Nairobi.
Evelyne has also had training in diverse fields, including in governance, leadership, policy, integrity, media, communication, and social development. Born and raised in Taita Taveta County to humble parents, she turned her childhood dreams into reality through her steadfast faith in God and determination to excel in education, and self-discipline. Her belief in media's positive impact drives her commitment to public service, good governance and social development.
She is happily married to Eng. Benson Mlambo Mwakina, HSC, and they have three grown-up children. Evelyne finds fulfillment in both her professional and personal life, striking a harmonious balance between dedication to her career and family.`;

function About() {
  // const [rSectionValues, setrSectionValues] = useState({
  //   imgUrl: rIMGURL,
  //   title: rTitle,
  //   text: rText,
  // });
  const [lSectionValues, setlSectionValues] = useState({
    imgUrl: lIMGURL,
    title: lTitle,
    text: lText,
  });

  const handleScroll = () => {
    const parallax = document.getElementById('about-landing');
    if (parallax) {
      const top = window.scrollY;
      parallax.style.backgroundPositionY = `${top * 0.03}rem`;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <section id="about-landing" className="about-landing">
        <Row justify="space-between" align="middle" style={{ height: '100vh' }}>
          <Col span={8}>
            <Title style={{ color: '#000', fontFamily: 'Cinzel Decorative' }}>
              MEET EVELYNE
            </Title>
          </Col>
        </Row>
      </section>
      <RightImageSection
        sectionValues={lSectionValues}
        setrSectionValues={setlSectionValues}
      />
      <Comments />
      <section className="time">
        <Row justify="space-around" align="middle">
          <Col span={16}>
            <Title
              level={2}
              style={{
                color: '#000',
                fontFamily: 'roboto',
                fontSize: '3rem',
              }}>{`EVELYNE'S JOURNEY`}</Title>
            <br />
            <Timeline
              mode="alternate"
              style={{
                color: '#000',
                fontSize: '2rem',
                alignItems: 'center',
              }}
              items={[
                {
                  color: 'black',
                  children: `Initiated KBC's in-house magazine, actively participated in anti-corruption activities, and served in various committees within and outside KBC.`,
                },
                {
                  children:
                    'Leadership management in China; Multimedia journalism perspectives in Rwanda; Gender-responsive policy in South Korea.',
                  color: 'black',
                },
                {
                  color: 'black',
                  children:
                    'Human rights workshops in Switzerland; Public service broadcasting training in Sweden.',
                },
                {
                  children: `Executive MBA, a Bachelor's degree in Philosophy and Literature, and a Post Graduate Diploma in Mass Communication.`,
                  color: 'black',
                },
                {
                  color: 'black',
                  children:
                    'Selected and edited news items, supervised news teams, and monitored global news trends at KBC',
                },
                {
                  children:
                    'Member of the Public Relations Society of Kenya, Proficient in English, Kiswahili, and Kitaita',
                  color: 'black',
                },
              ]}
            />
          </Col>
        </Row>
        {/* <Button type="primary" shape="round" size="large">
          DOWNLOAD RESUME
        </Button> */}
      </section>
      {/* <LeftImageSection
        sectionValues={rSectionValues}
        setrSectionValues={setrSectionValues}
      /> */}
    </>
  );
}

export default About;
